<template>
  <div
    class="GenericSideMenu modalContent modalHeight modalContentWidth
    fixed top-0 z-modalsContent translate-x-[0%]
    flex flex-col"
    :class="[ position, modalSizeClass ]"
  >
    <!-- head -->
    <div
      class="shrink-0 grow-0 basis-56 flex w-full items-center justify-between px-16 border-b border-light select-none"
      :class="[ headerClass ]"
    >
      <button
        v-if="showBack"
        class="w-24 h-24 cursor-pointer"
        @click="$emit('back')"
      >
        <img

          src="/icons/arrow-left.svg"
          class="h-24 w-24"
          alt="Back"
        >
      </button>
      <div v-if="title !== ''" class="type-headline-2xl leading-single flex items-start">
        {{ title }}
        <span v-if="titleMeta" class="type-headline-sm ml-8 -mt-2">{{ titleMeta }}</span>
      </div>
      <div v-else class="h-[22px]">
        <img
          v-if="showLogo"
          :src="`/logos/${globalContent.getLogoUrl}-inv.svg`"
          alt="logo"
          class="h-[22px]"
        >
      </div>
      <button class="w-24 h-24 text-right cursor-pointer" @click="closeMenu">
        <img
          src="/icons/close.svg"
          class="h-24 w-24 inline-block"
          alt="Close"
        >
      </button>
    </div>

    <!-- content -->
    <div
      class="p-24 basis-full overflow-auto"
      :class="[ contentClass ]"
    >
      <slot />
    </div>

    <!-- bottom -->
    <div
      class="bg-lighter shrink-0 empty:hidden"
      :class="[ bottomClass ]"
      @click="closeMenu"
    >
      <slot name="bottom" />
    </div>
    <Teleport v-if="selfContained && !noBackground" to="body">
      <div
        class="modalBg modalHeight"
        @click="$emit('close')"
      />
    </Teleport>
  </div>
</template>

<script setup lang="ts">
import { useUiStore } from '~/store/ui';
import { useGlobalContentStore } from '~/store/globalContent';
const globalContent = useGlobalContentStore();

const emit = defineEmits(['back', 'close']);
const uiStore = useUiStore();

interface Props {
  title?: string,
  titleMeta?: number,
  showBack?: boolean,
  showLogo?: boolean,
  position?: 'left' | 'right',
  headerClass?: string,
  contentClass?: string,
  bottomClass?: string,
  selfContained?: boolean, // comes with background, does not use ui store
  noBackground?: boolean, // for self contained
  small?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  title: '',
  showBack: false,
  showLogo: false,
  position: 'left',
  headerClass: '',
  contentClass: 'p-24',
  bottomClass: 'basis-[75px]',
  selfContained: false,
  noBackground: false,
  small: false,
});

const modalSizeClass = computed(() => {
  return props.small ? 'modalContentWidthSmall' : 'modalContentWidth';
});

const closeMenu = () => {
  if (!props.selfContained) {
    uiStore.closeAllModal();
  }
  emit('close');
};

</script>

<style scoped lang="postcss">
.left {
  @apply left-0;
}
.right {
  @apply right-0;
}
</style>
